import React from "react"
import { Link } from "gatsby"
import Navbar from "../components/navbar"
import Header from "../components/header"
import Footer from "../components/footer"
import Button from "../components/button"
import { FaEnvelope, FaChevronRight, FaArrowLeft, FaLaptop } from "react-icons/fa"

export default () => (
  <div style={{ color: `purple` }}>
    <title>Scott Murray | Software Engineer</title>
    <Navbar></Navbar>
    <Header headerText="Scott." />
    <br></br>
    <p>I'm an engineer, of the Software and Mechanical varieties 💻⚙️</p>
    <p>I'm also a keen city cyclist 🚲 and sustainability, permaculture & rewilding enthusiast 🌱🌳</p>
    <Link to="/about">
      <Button>
        About
        <FaChevronRight />
      </Button>
    </Link>
    <a href="mailto:sm@smrry.com?Subject=Website%20Enquiry" target="_blank">
      <Button secondary>
        <FaEnvelope />
      </Button>
    </a>
    <Footer />
  </div>
)
